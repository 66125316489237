export const commonEnv = {
    production: false,
    environmentName: "development",
    //apiBaseURL: "https://stgblvd.silvermirror.com",
    apiBaseURL: "https://blvd.silvermirror.com",
   // apiBaseURL: "https://blvdpdstg.silvermirror.com",
    paymentApiBaseURL: "https://pci.boulevard.app",
    giftcard_location_id: "urn:blvd:Location:704277ae-8958-4ba0-97a3-0e991477add7",
    nutrition_location_id: "urn:blvd:Location:72fd44e3-1c00-4a7a-a2a2-92fc9aaaee97", // Silver Mirror Nutrition
    nutrition_tag_id:"493632c5-2013-4e15-aac2-dfab0fdbba18", // Nutrition
    version: "3.1.44"
};
